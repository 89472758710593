import {
  ADD_ERROR_NOTIFICATION,
  ADD_NOTIFICATION,
  ADD_SUCCESS_NOTIFICATION,
  NotificationType,
} from "@/types/notification";
import { RootState } from "@/types/state";
import { Store } from "vuex";

export function useNotifications(store: Store<RootState>) {
  async function addErrorNotification(message: string) {
    store.dispatch(ADD_ERROR_NOTIFICATION, {
      message,
    });
  }

  async function addSuccessNotification(message: string) {
    store.dispatch(ADD_SUCCESS_NOTIFICATION, {
      message,
    });
  }

  async function addWarnNotification(message: string) {
    store.dispatch(ADD_NOTIFICATION, {
      message,
      type: NotificationType.WARN,
    });
  }

  return { addSuccessNotification, addWarnNotification, addErrorNotification };
}
