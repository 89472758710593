export default class GetAccountsReceivableRequest {
  constructor(
    public cust: string,
    public rangeStart: number,
    public rangeEnd: number,
    public dueDateStart: string,
    public dueDateEnd: string,
    public invoiceDateStart: string,
    public invoiceDateEnd: string,
    public registerDateStart: string,
    public registerDateEnd: string,
    public sortBy: ARSortTypes,
    public sortOrder: string,
    public correls: string,
    public statuses: Array<string>,
    public types: Array<string>,
  ) {}
}
export enum ARSortTypes {
  InvoiceDate = "invoice.date",
  DueDate = "due.date",
  RegisterDate = "reg.date",
}

export enum AccountsReceivableAgingTypes {
  InvoiceDate = "Invoice Date",
  DueDate = "Due Date",
  RegisterDate = "Register Date",
}
