import ClientAPI from "./ClientAPI";
import ApiService from "./ApiService";
import GetAccountsReceivableRequest from "@/types/services/accountsReceivable";

export default class ARService extends ApiService {
  constructor() {
    super();
  }
  async getARRecords(request: Partial<GetAccountsReceivableRequest>) {
    return ClientAPI.Get(`ar`, request);
  }
  async getARRecord(id: string) {
    const params = {
      id,
    };
    return ClientAPI.Get(`ar/${id}`, params);
  }

  async getAging(cust_id: string) {
    const params = {
      cust: cust_id,
    };
    return ClientAPI.Get(`aging`, params);
  }

  async getStatement(cust_id: string, cutoff_date: string, email?: any) {
    let params: any;
    if (email) {
      params = {
        email: email.To,
        subject: email.Subject,
        body: email.Body,
        from: email.From,
        cc: email.Cc,
        bcc: email.Bcc,
        html: email.Html,
        cust: cust_id,
        cutoff_date: cutoff_date,
      };
    } else {
      params = {
        cust: cust_id,
        cutoff_date: cutoff_date,
      };
    }

    return ClientAPI.Get(`statement`, params);
  }
  postInvoice(invoice: any) {
    const request = { newAr: invoice };
    return this.Post(`ar`, request);
  }
}
