
import { defineComponent, inject, ref } from "vue";
import { mapGetters, mapActions, mapState, useStore } from "vuex";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import Dropdown from "primevue/dropdown";
import Tooltip from "primevue/tooltip";
import Badge from "primevue/badge";
import EmailFileDialog from "@/components/UI/EmailFileDialog.vue";

import InvoiceService from "@/services/InvoiceService";
import Utils from "@/utility/utils";
import GetAccountsReceivableRequest, {
  AccountsReceivableAgingTypes,
  ARSortTypes,
} from "@/types/services/accountsReceivable";
import { useFetchArRecords } from "@/composables/AR/useFetchAccountReceivables";
import ARService from "@/services/ARService";
import { useLazyLoadPagination } from "@/composables/DataTable/useLazyLoadPagination";
import { useNotifications } from "@/composables/Notification/useNotifications";
import _ from "lodash";

const arService = new ARService();

export default defineComponent({
  components: {
    DataTable,
    Column,
    Button,
    InputText,
    Calendar,
    Row,
    ColumnGroup,
    Badge,
    EmailFileDialog,
    Dropdown,
  },
  directives: {
    tooltip: Tooltip,
  },
  props: {
    tableName: {
      type: String,
    },
    isCustomTab: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Object,
      default: () => ({}) as any,
    },
    showType: {
      type: Boolean,
      default: true,
    },
    showToPay: {
      type: Boolean,
      default: true,
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    forceFetch: {
      type: Boolean,
      default: false,
    },
    loadOnCreate: {
      type: Boolean,
      default: true,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    hidePoNo: {
      type: Boolean,
      default: true,
    },
    noDefaultSort: {
      type: Boolean,
      default: false,
    },
    hideFilters: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["rowClick", "update:forceFetch"],
  setup(props) {
    const custId = inject<any>("customerId");
    const dateRange = ref<any>();
    const selectedStatus = ref("");
    const selectedType = ref("");
    const emptyTableLabel = ref("Invoices have not been loaded.");
    const store = useStore();
    const {
      addSuccessNotification,
      addWarnNotification,
      addErrorNotification,
    } = useNotifications(store);
    const { fetchArRecords } = useFetchArRecords(arService);
    const agingType = inject("accountsReceivableAgingType");

    const getReceivables = async (isReset = false) => {
      if (isReset) {
        records.value = [];
      }
      const rangeStart = records.value.length + 1;
      const rangeEnd = records.value.length + 100;
      const request = {
        statuses: [selectedStatus.value],
        Client: store.getters["session/getClient"],
        types: [selectedType.value],
        correls: "cust_name",
        rangeStart,
        rangeEnd,
      } as Partial<GetAccountsReceivableRequest>;
      if (custId) {
        request.cust = custId.value;
      }
      let [dateStart, dateEnd] = dateRange.value || [];
      if (dateStart) {
        dateStart = Utils.formatDate(dateStart);
      }
      if (dateEnd) {
        dateEnd = Utils.formatDate(dateEnd);
      }

      if ((agingType as any) === AccountsReceivableAgingTypes.DueDate) {
        request.dueDateStart = dateStart;
        request.dueDateEnd = dateEnd;
        request.sortBy = ARSortTypes.DueDate;
      } else if (
        (agingType as any) === AccountsReceivableAgingTypes.RegisterDate
      ) {
        request.registerDateStart = dateStart;
        request.registerDateEnd = dateEnd;
        request.sortBy = ARSortTypes.RegisterDate;
      } else {
        request.invoiceDateStart = dateStart;
        request.invoiceDateEnd = dateEnd;
        request.sortBy = ARSortTypes.InvoiceDate;
      }

      if (props.noDefaultSort) {
        request.sortBy = undefined;
      }

      try {
        isLoading.value = true;
        const resp = await fetchArRecords(request);
        totalRecords.value = resp.total_records_found;
        records.value.push(...resp.ar_items);
      } catch (error) {
        addErrorNotification("Failed to fetch receivables. Please try again.");
      } finally {
        isLoading.value = false;
        emptyTableLabel.value = "No receivables found.";
      }
    };

    const { records, totalRecords, isLoading, first, rowsPerPage, onPage } =
      useLazyLoadPagination(() => getReceivables());

    return {
      fetchArRecords,
      first,
      rowsPerPage,
      isLoading,
      onPage,
      records,
      totalRecords,
      getReceivables,
      dateRange,
      agingType,
      selectedStatus,
      selectedType,
      addSuccessNotification,
      addWarnNotification,
      addErrorNotification,
      custId,
      emptyTableLabel,
    };
  },
  data() {
    return {
      showEmailInvoiceDialog: false,
      currentInvoiceId: "",
      filters: {
        ar_id: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        cust_name: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        status: { value: [], matchMode: FilterMatchMode.IN },
        type: { value: [], matchMode: FilterMatchMode.IN },
      },
      types: [
        { name: "Credit Memo", code: "CM" },
        { name: "Debit Memo", code: "DM" },
        { name: "Invoice", code: "IN" },
        { name: "On Account", code: "OA" },
      ],
      stati: [
        { name: "Outstanding", code: "O" },
        { name: "Paid", code: "P" },
        { name: "Payment Pending", code: "Y" },
      ],
      hoverPayBottonIndex: -1,
      hover: [],
      invoiceService: new InvoiceService(),
    };
  },
  async created() {
    this.dateRange = this.range ? this.range.range : null;
    if (this.loadOnCreate) {
      this.getReceivables();
    }

    this.rowsPerPage = this.itemsPerPage;
  },
  computed: {
    ...mapState(["accountingReceivables"]),
    ...mapGetters({
      getClient: "session/getClient",
      getReceivablesByLabel: "accountingReceivables/getReceivablesByLabel",
      itemsPerPage: "accountingReceivables/getItemsPerPage",
      getInvoice: "accountingReceivables/getInvoice",
      loadingPDFInvoices: "accountingReceivables/getLoadingInvoices",
      invoicesToPay: "invoice/getInvoicesToPay",
    }),
    columnCount(): number {
      let count = this.custId ? 8 : 9;
      if (!this.showType) {
        count--;
      }
      if (!this.showInvoiceAmountColumn) {
        count--;
      }
      return count;
    },
    getTotalBalance(): string {
      let total = 0.0;
      if (!this.records || !this.records.length)
        return this.formatCurrency(total);
      this.records.forEach((invoice: any) => {
        total += parseFloat(invoice.balance ?? "0.00");
      });
      return this.formatCurrency(total);
    },
    tableColumnHeaderClass(): string {
      return !this.compact ? "font-bold text-base" : "";
    },
    tableAmountColumnBodyClass(): string {
      return !this.compact
        ? "font-semibold text-right pr-2 lg:pr-3 w-2"
        : "text-right";
    },
    textBaseClass(): string {
      return !this.compact ? "text-base" : "";
    },
    currencyClass(): string {
      return !this.compact ? "text-base text-right" : "";
    },
    showInvoiceAmountColumn(): boolean {
      return this.records.some((ar: any) => ar.invoice_amt);
    },
  },
  methods: {
    ...mapActions({
      addIsLoading: "accountingReceivables/addIsLoading",
      updateCustomDateRange: "accountingReceivables/updateCustomDateRange",
      addPDFLoading: "accountingReceivables/addIdLoading",
      addPDFInvoice: "accountingReceivables/addPDFInvoice",
      removeIdLoading: "accountingReceivables/removeIdLoading",
      postInvoiceToPay: "invoice/addInvoiceToPay",
      removeInvoiceToPay: "invoice/removeInvoiceToPay",
    }),
    async handleClearFilterByStatus(): Promise<void> {
      this.selectedStatus = "";
      this.getReceivables(true);
    },
    async handleClearFilterByType(): Promise<void> {
      this.selectedType = "";
      this.getReceivables(true);
    },
    async handleFilter(): Promise<void> {
      this.getReceivables(true);
    },
    isSelectedRow(data: any) {
      return (
        this.selectedItems.find((item) => item === data.ar_id) && "bg-blue-100"
      );
    },
    handleHideCalendar(event: any) {
      if (!event[0] || event[1] === null) return;
      this.updateCustomDateRange({
        label: this.range ? this.range.label : "",
        range: this.dateRange,
      });

      this.getReceivables(true);
    },
    formatCurrency(value: number) {
      let number = typeof value === "string" ? parseFloat(value) : value;
      return `$${number.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    },
    onRowSelect(data: any) {
      this.$emit("rowClick", data);
    },
    getTypeTitle(type: string): string {
      return this.types.find((typ: any) => typ.code === type)?.name ?? "";
    },
    invoiceIcon(invoiceId: string) {
      let downloaded = this.getInvoice;
      let downloading = this.loadingPDFInvoices.includes(invoiceId);
      return {
        "pi pi-download":
          downloaded.find((i: any) => i.invoice_num === invoiceId) ===
            undefined && !downloading,
        "pi pi-spin pi-spinner": downloading,
        "pi pi-file-pdf":
          downloaded.find((i: any) => i.invoice_num === invoiceId) !==
            undefined && !downloading,
      };
    },

    fetchPDFInvoice(invoiceId: string) {
      const pdf = this.getInvoice?.find(
        (invoice: any) => invoice.invoice_num === invoiceId,
      );

      if (pdf !== undefined) {
        window.open(pdf.pdf, "_blank");
      } else {
        this.addPDFLoading(invoiceId);

        this.invoiceService
          .getInvoice(invoiceId)
          .then((response: any) => {
            const bufferArray = Utils.base64ToArrayBuffer(response);
            const blobStore = new Blob([bufferArray], {
              type: "application/pdf",
            });
            const data = window.URL.createObjectURL(blobStore);
            this.addPDFInvoice({ invoice_num: invoiceId, pdf: data });

            this.removeIdLoading(invoiceId);

            window.open(data, "_blank");
          })
          .catch(() => {
            this.addErrorNotification(
              "Failed to download invoice preview. Please try again.",
            );

            this.removeIdLoading(invoiceId);
          });
      }
    },
    showEmailModal(id: string) {
      this.showEmailInvoiceDialog = true;
      this.currentInvoiceId = id;
    },
    sendEmail(data: any) {
      this.invoiceService
        .getInvoice(this.currentInvoiceId, data)
        .then((response: any) => {
          if (response === "success") {
            this.addSuccessNotification(
              `Invoice #${this.currentInvoiceId} has been emailed successfully`,
            );
          } else {
            this.addErrorNotification("Invoice was not sent");
          }
        })
        .catch((error) => {
          this.addErrorNotification(`Invoice could not be sent: ${error}`);
        })
        .catch((error) => {
          this.addErrorNotification(`Invoice could not be sent: ${error}`);
        });
    },
    existsInPayArray(invoice: any) {
      const found = this.invoicesToPay.find((inv: any) => {
        return inv.arId == invoice.ar_id;
      });
      if (found) return true;

      return false;
    },
    addInvoiceToPay(invoice: any) {
      this.postInvoiceToPay({
        arId: invoice.ar_id,
        balance: parseFloat(invoice.balance),
        dueDate: invoice.due_date,
        cust: invoice.cust,
      });

      this.addSuccessNotification(
        `Invoice #${invoice.ar_id} added to payment.`,
      );
    },
    removeInvoice(data: any): void {
      this.removeInvoiceToPay({
        arId: data.ar_id,
        balance: parseFloat(data.balance),
      });

      this.addWarnNotification(`Invoice #${data.ar_id} removed from payment.`);
    },
    formatStringDate(dueDate: any) {
      return Utils.formatDate(dueDate, !this.compact);
    },
  },
  watch: {
    async range(val: any, oldVal: any) {
      if (_.isEqual(val, oldVal)) return;
      this.dateRange = val.range || val;
      if (!this.isCustomTab) {
        this.getReceivables(true);
      }
    },
    async forceFetch(val: boolean) {
      if (val && !this.isLoading) {
        this.getReceivables(true);
        this.$emit("update:forceFetch", false);
      }
    },
  },
});
