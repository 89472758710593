import ARService from "@/services/ARService";
import GetAccountsReceivableRequest from "@/types/services/accountsReceivable";
import store from "@/store";
import { ADD_ERROR_NOTIFICATION } from "@/types/notification";

export function useFetchArRecords(service: ARService) {
  async function fetchArRecords(
    request: Partial<GetAccountsReceivableRequest>,
  ) {
    try {
      return await service.getARRecords(request);
    } catch (error: any) {
      store.dispatch(ADD_ERROR_NOTIFICATION, {
        message: `Error Displaying Accounts Receivable Records. ${
          error.error || error.message
        }.`,
      });
    }
  }

  return { fetchArRecords };
}
